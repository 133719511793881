<template>
  <div class="img-reply-list-item">
    <span class="number">
        {{ index + 1 }}. 图片：
    </span>
    
    <el-image
        class="image"
        referrerpolicy="no-referrer"
        :src="imageUrl"
        :preview-src-list="[imageUrl]"
        fit="scale-down"
    />
        
    <div class="edit-btns">
        <i class="el-icon-edit" @click="edit" />
        <i class="el-icon-delete" @click="remove" />
    </div>
  </div>
</template>

<script>
export default {
    name: 'text-reply-list-item',
    props: {
        index: {
            type: Number,
            required: true,
        },
        item: {
            type:Object,
            required: true            
        }
    },
    data() {
        return {
            imageUrl: ''
        }
    },
    methods: {
        edit() {
            this.$emit('edit', this.item);
        },
        remove() {
            this.$emit('remove', this.item);
        },
        async refreshImageUrl() {
            console.log(this.item)
            let matInfo = await this.$http.doApi('fw-wx-material-get', {
                id: this.item.content
            });
            this.imageUrl = matInfo.url;
        }
    },
    created() {
        this.refreshImageUrl();
    },
    watch: {
        item: {
            deep: true,
            handler() {
                this.refreshImageUrl();
            }
        }
    }
};
</script>

<style lang="less">
.img-reply-list-item{
    display: flex;
    border-bottom: 1px solid #000000;
    margin-bottom: 1rem;
    width: 80%;
    line-height: 50px;

    .image {
        width: 50px;
        height: 50px;
    }
    i {
        font-size: 1.5rem;
    }
}
</style>